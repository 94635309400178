@import url('https://fonts.googleapis.com/css?family=Courgette');
body {
  font-family: 'Open Sans', sans-serif;
  color: #555555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
}

a {
  color: #ffffff;
  transition: all 0.5s ease;
}

a:hover {
  color: #2eca6a;
  text-decoration: none;
}

.link-two {
  color: #000000;
  transition: all 0.5s ease;
}

.link-two:hover {
  text-decoration: underline;
  color: #000000;
}

.link-one {
  color: #000000;
  transition: all 0.5s ease;
}

.link-one:hover {
  color: #000000;
  text-decoration: none;
}

.link-icon {
  color: #000000;
  font-weight: 500;
}

.link-icon span {
  font-size: 14px;
  padding-left: 4px;
  vertical-align: middle;
}

.link-a {
  color: #ffffff;
  text-decoration: none;
}

.link-a:hover {
  color: #ffffff;
  text-decoration: none;
}

.link-a span {
  font-size: 18px;
  vertical-align: middle;
  margin-left: 5px;
}

.text-brand {
  color: #000000;
  font-size: 2rem;
  font-weight: 600;
}
.text-page-heading {
  color: #000000;
  font-size: 2rem;
  font-weight: 600;
  padding-top: 1rem;
}
.color-a {
  color: #000000;
}

.color-b {
  color: #2eca6a;
}

.color-d {
  color: #adadad;
}

.color-text-a {
  color: #555555;
}

.no-margin {
  margin: 0;
}
hr.hr-line {
  border: 1px solid #2eca6a;
  border-radius: 2px;
  width: 7.5vh;
  float: center;
}
/*------/ Nav Pills  /------*/

.nav-pills-a.nav-pills .nav-link {
  color: #000000;
  position: relative;
  font-weight: 600;
}

.nav-pills-a.nav-pills .nav-link.active {
  background-color: transparent;
}

.nav-pills-a.nav-pills .nav-link.active:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: #2eca6a;
  z-index: 2;
}

/*------/ Bg Image /------*/

.bg-image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

/*------/ List a /------*/

.list-a {
  display: inline-block;
  line-height: 2;
  padding: 0;
  list-style: none;
}

.list-a li {
  position: relative;
  width: 50%;
  float: left;
  padding-left: 25px;
  padding-right: 5px;
}

.list-a li:before {
  content: '';
  width: 10px;
  height: 2px;
  position: absolute;
  background-color: #313131;
  top: 15px;
  left: 0;
}

/*------/ Pagination-a /------*/

.pagination-a .pagination .page-link {
  margin: 0 0.2rem;
  border-color: transparent;
  padding: 0.5rem 0.8rem;
  color: #000000;
}

.pagination-a .pagination .page-link:hover,
.pagination-a .pagination .page-link:active,
.pagination-a .pagination .page-link:focus {
  background-color: #2eca6a;
}

.pagination-a .pagination .page-link span {
  font-size: 1.2rem;
}

.pagination-a .pagination .next .page-link {
  padding: 0.4rem 0.9rem;
}

.pagination-a .pagination .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-a .pagination .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-a .pagination .page-item.disabled .page-link {
  padding: 0.4rem 0.9rem;
  color: #555555;
}

.pagination-a .pagination .page-item.active .page-link {
  background-color: #2eca6a;
}

.a {
  color: #2eca6a;
  font-size: 1.3rem;
}

/*------/ Icon Box /------*/

.icon-box .icon-box-icon {
  display: table-cell;
  vertical-align: top;
  font-size: 36px;
  color: #000000;
  width: 50px;
  padding-top: 8px;
}

.icon-box .icon-box-content {
  padding-top: 18px;
}

/*------/ Space Padding /------*/

.section-t8 {
  padding-top: 8rem;
}

.section-t4 {
  padding-top: 4rem;
}

.section-t3 {
  padding-top: 3rem;
}

.section-b2 {
  padding-bottom: 2rem;
}

.section-tb85 {
  padding: 8rem 0 5rem 0;
}

/*------/ Title /------*/

.title-wrap {
  padding-bottom: 4rem;
}

.title-a {
  font-size: 2.6rem;
  font-weight: 600;
}

.title-link {
  font-size: 1.2rem;
  font-weight: 300;
  padding-top: 1.2rem;
}

.title-link a {
  color: #313131;
}

.title-link span {
  font-size: 20px;
  padding-left: 4px;
  vertical-align: middle;
}

.title-box-d {
  padding-bottom: 1.8rem;
  margin-bottom: 1rem;
  position: relative;
}

.title-box-d .title-d {
  font-weight: 600;
  font-size: 2rem;
}

.title-box-d .title-d:after {
  content: '';
  position: absolute;
  width: 70px;
  height: 4px;
  background-color: #2eca6a;
  bottom: 20px;
  left: 0;
}

/*------/ Display Table & Table Cell /------*/

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

/*------/ Ul Resect /------*/

.ul-resect ul,
.list-a ul,
.box-comments ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

/*------/ Overlay /------*/

.overlay-a {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 27%,
    rgba(0, 0, 0, 0.65) 90%
  );
}

.overlay {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
}

/*------/ Owl carousel /------*/

.owl-theme .owl-dots {
  text-align: center;
  margin-top: 18px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  width: 16px;
  height: 9px;
  margin: 5px 5px;
  background: #000000;
  border: 0px solid #000000;
  display: block;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
  border-radius: 0px;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #2eca6a;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #2eca6a;
  width: 35px;
}

.owl-arrow .owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.8rem;
}

.owl-arrow .owl-nav .owl-prev,
.owl-arrow .owl-nav .owl-next {
  display: inline-block;
  transition: all 0.6s ease-in-out;
  color: #000000;
}

.owl-arrow .owl-nav .owl-prev.disabled,
.owl-arrow .owl-nav .owl-next.disabled {
  transition: all 0.6s ease-in-out;
  color: #adadad;
}

.owl-arrow .owl-nav .owl-next {
  margin-left: 15px;
}

.nav-arrow-a .owl-arrow .owl-nav {
  font-size: 1.8rem;
  margin-top: -110px;
}

.nav-arrow-a .owl-arrow .owl-nav .owl-next {
  margin-left: 15px;
}

.nav-arrow-b .owl-arrow .owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev,
.nav-arrow-b .owl-arrow .owl-nav .owl-next {
  padding: 0.7rem 1.5rem !important;
  display: inline-block;
  transition: all 0.6s ease-in-out;
  color: #ffffff;
  background-color: #2eca6a;
  opacity: 0.9;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev.disabled,
.nav-arrow-b .owl-arrow .owl-nav .owl-next.disabled {
  transition: all 0.6s ease-in-out;
  color: #ffffff;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev:hover,
.nav-arrow-b .owl-arrow .owl-nav .owl-next:hover {
  background-color: #26a356;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-next {
  margin-left: 0px;
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: #2eca6a;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

/* Prelaoder */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: '';
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f2f2f2;
  border-top: 6px solid #2eca6a;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*======================================
//--//-->   NAVBAR
======================================*/

.navbar-default {
  transition: all 0.5s ease-in-out;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
}

.navbar-default .nav-search {
  color: #000000;
  font-size: 1rem;
}

.navbar-default.navbar-reduce {
  box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
}

.navbar-default.navbar-trans,
.navbar-default.navbar-reduce {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.navbar-default.navbar-trans .nav-item,
.navbar-default.navbar-reduce .nav-item {
  position: relative;
  padding-right: 10px;
  padding-bottom: 8px;
  margin-left: 0;
}

.navbar-default.navbar-trans .nav-link,
.navbar-default.navbar-reduce .nav-link {
  font-size: 1.1rem;
  color: #000000;
  font-weight: 600;
  letter-spacing: 0.03em;
  transition: all 0.1s ease-in-out;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.navbar-default.navbar-trans .nav-link:before,
.navbar-default.navbar-reduce .nav-link:before {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 0;
  background-color: #2eca6a;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  transform-origin: right;
  transition: opacity 0.2s ease-out 0.3s, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out 0.3s;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out 0.3s,
    -webkit-transform 0.2s ease-out;
}

.navbar-default.navbar-trans .nav-link:hover,
.navbar-default.navbar-reduce .nav-link:hover {
  color: #000000;
}

.navbar-default.navbar-trans .nav-link:hover:before,
.navbar-default.navbar-reduce .nav-link:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.navbar-default.navbar-trans .show > .nav-link:before,
.navbar-default.navbar-trans .active > .nav-link:before,
.navbar-default.navbar-trans .nav-link.show:before,
.navbar-default.navbar-trans .nav-link.active:before,
.navbar-default.navbar-reduce .show > .nav-link:before,
.navbar-default.navbar-reduce .active > .nav-link:before,
.navbar-default.navbar-reduce .nav-link.show:before,
.navbar-default.navbar-reduce .nav-link.active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.navbar-default.navbar-trans .nav-link:before {
  background-color: #2eca6a;
}

.navbar-default.navbar-trans .nav-link:hover {
  color: #000000;
}

.navbar-default.navbar-trans .show > .nav-link,
.navbar-default.navbar-trans .active > .nav-link,
.navbar-default.navbar-trans .nav-link.show,
.navbar-default.navbar-trans .nav-link.active {
  color: #000000;
}

.navbar-default.navbar-reduce {
  transition: all 0.5s ease-in-out;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-default.navbar-reduce .nav-link {
  color: #000000;
}

.navbar-default.navbar-reduce .nav-link:before {
  background-color: #2eca6a;
}

.navbar-default.navbar-reduce .nav-link:hover {
  color: #000000;
}

.navbar-default.navbar-reduce .show > .nav-link,
.navbar-default.navbar-reduce .active > .nav-link,
.navbar-default.navbar-reduce .nav-link.show,
.navbar-default.navbar-reduce .nav-link.active {
  color: #000000;
}

.navbar-default.navbar-reduce .navbar-brand {
  color: #000000;
}

.navbar-default .dropdown .dropdown-menu {
  border-top: 0;
  border-left: 4px solid #2eca6a;
  border-right: 0;
  border-bottom: 0;
  -webkit-transform: translate3d(0px, -40px, 0px);
  transform: translate3d(0px, -40px, 0px);
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.3, 0.65, 0.355, 1) 0s,
    opacity 0.31s ease 0s, height 0s linear 0.36s;
  margin: 0;
  border-radius: 0;
  padding: 12px 0;
}

.navbar-default .dropdown .dropdown-menu .dropdown-item {
  padding: 12px 18px;
  transition: all 500ms ease;
  font-weight: 600;
  min-width: 220px;
}

.navbar-default .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #ffffff;
  color: #2eca6a;
  transition: all 500ms ease;
}

.navbar-default .dropdown .dropdown-menu .dropdown-item.active {
  background-color: #ffffff;
  color: #2eca6a;
}

.navbar-default .dropdown:hover .dropdown-menu {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=1);
}

/*------/ Hamburger Navbar /------*/

.navbar-toggler {
  position: relative;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background-color: #000000;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: -webkit-transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

/*======================================
//--//-->   BOX COLAPSE
======================================*/

.box-collapse {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1040;
  background-color: #ffffff;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: all 0.6s ease;
}

.box-collapse .title-box-d {
  top: 30px;
  left: 60px;
  opacity: 0;
  transition: all 1s ease;
  transition-delay: 0.3s;
}

.box-collapse-wrap {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 6.5rem;
  bottom: 5rem;
  padding-left: 10%;
  padding-right: 10%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  -webkit-transform: translateY(3rem);
  transform: translateY(3rem);
  transition: opacity 0.5s 0.5s ease, -webkit-transform 0.5s 0.5s ease;
  transition: transform 0.5s 0.5s ease, opacity 0.5s 0.5s ease;
  transition: transform 0.5s 0.5s ease, opacity 0.5s 0.5s ease,
    -webkit-transform 0.5s 0.5s ease;
}

.box-collapse-open .click-closed {
  visibility: visible;
}

.box-collapse-open .box-collapse {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.07);
  opacity: 1;
}

.box-collapse-open .box-collapse .title-box-d {
  -webkit-transform: translate(0);
  transform: translate(0);
  opacity: 1;
}

.box-collapse-open .box-collapse-wrap {
  -webkit-transform: translate(0);
  transform: translate(0);
  opacity: 1;
}

.box-collapse-closed .box-collapse {
  opacity: 0.7;
  transition-delay: 0s;
}

.box-collapse-closed .box-collapse .title-box-d {
  opacity: 0;
  transition-delay: 0s;
}

.box-collapse-closed .box-collapse .form-a {
  opacity: 0;
  transition-delay: 0s;
}

.click-closed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 1035;
  background-color: #000000;
  opacity: 0.4;
}

.close-box-collapse {
  position: absolute;
  z-index: 1050;
  top: 2rem;
  font-size: 3.5rem;
  line-height: 1;
  color: #000000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.right-boxed {
  right: 4.2857rem;
}

/*======================================
//--//-->   INTRO
======================================*/

.intro {
  height: 90vh;
  position: relative;
  color: #ffffff;
}

.intro .owl-theme .owl-dots {
  position: absolute;
  bottom: 70px;
  right: 50px;
}

.intro .owl-theme .owl-dots .owl-dot span {
  background: #ffffff;
  border: 0px solid #ffffff;
}

.intro .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #2eca6a;
}

.intro .owl-theme .owl-dots .owl-dot.active span {
  background-color: #2eca6a;
}

.intro .intro-item {
  padding-top: 2rem;
}

.intro .carousel-item-a {
  position: relative;
  height: 90vh;
}

.intro .carousel-item-a.bg-image {
  background-attachment: scroll;
}

.intro .intro-content {
  position: absolute;
}

.intro .intro-body {
  padding-left: 1rem;
}

.intro .intro-body .price-a {
  color: #ffffff;
  padding: 1rem 1.5rem;
  border: 2px solid #2eca6a;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.intro .spacial {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  color: #ffffff;
}

.intro .intro-title-top {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #ffffff;
  letter-spacing: 0.05em;
}

.intro .intro-title {
  transition-duration: 9s !important;
  color: #ffffff;
  font-weight: 700;
  font-size: 2.8rem;
  text-transform: uppercase;
}

.intro .intro-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.intro .intro-subtitle.intro-price {
  padding-top: 1rem;
  font-size: 1.2rem;
}

/*------/ Intro Single /------*/

.intro-single {
  padding: 12rem 0 3rem;
}

.intro-single .title-single-box {
  padding: 1rem 0 1rem 2rem;
}

.intro-single .title-single-box {
  border-left: 3px solid #2eca6a;
}

.intro-single .title-single-box .title-single {
  font-weight: 600;
  font-size: 2.2rem;
}

.intro-single .breadcrumb-box {
  padding: 1rem 0 0 0.5rem;
}

.intro-single .breadcrumb {
  background-color: transparent;
  padding-right: 0;
  padding-left: 0;
}

/*======================================
//--//-->   SERVICES - CARD-C
======================================*/

.card-box-c {
  position: relative;
}

.card-header-c {
  padding: 0 1rem 1rem 1rem;
}

.card-body-c {
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.card-footer-c {
  padding-left: 0.5rem;
}

.card-box-ico {
  padding: 1rem 3rem 1rem 2.5rem;
  border: 5px solid #2eca6a;
}

.card-box-ico span {
  font-size: 4rem;
  color: #000000;
}

.title-c {
  font-size: 2.5rem;
  font-weight: 600;
  margin-left: -40px;
}

/*======================================
//--//-->   AGENTS - CARD-D
======================================*/

.card-box-d .card-overlay-hover {
  transition: all 0.2s ease-in-out;
  padding: 15px 40px 15px 35px;
}

.card-box-d .title-d {
  transition: 0.3s ease-in-out;
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.card-box-d .content-d {
  opacity: 0;
  transition: 0.5s ease-in-out;
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
}

.card-box-d .info-agents {
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.card-box-d .card-footer-d {
  transition: 0.5s ease-in-out;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
}

.card-box-d .list-inline-item:not(:last-child) {
  margin-right: 25px;
}

.card-box-d:hover .card-overlay-hover {
  background-color: #2eca6a;
  opacity: 0.9;
}

.card-box-d:hover .title-d,
.card-box-d:hover .content-d,
.card-box-d:hover .info-agents,
.card-box-d:hover .card-footer-d {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/*------/ Agent Single /------*/

.agent-info-box .socials-footer {
  margin-top: 2rem;
}

.agent-info-box .socials-footer li {
  margin-right: 1.5rem;
}

/*======================================
//--//-->   ABOUT
======================================*/

.section-about .title-vertical {
  position: absolute;
  letter-spacing: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 50px;
  text-align: left;
  font-size: 1.8rem;
  width: 300px;
  font-weight: 700;
  text-align: right;
}

.about-img-box {
  padding-left: 1rem;
  position: relative;
}

.sinse-box {
  background-color: #2eca6a;
  padding: 1rem 2rem;
  position: absolute;
  left: 15px;
  bottom: 10px;
}

.sinse-box .sinse-title {
  font-weight: 700;
  letter-spacing: 0.043em;
  font-size: 1rem;
}

.sinse-box .sinse-title span {
  position: absolute;
}

.sinse-box .sinse-title span:after {
  content: ' ';
  width: 35px;
  height: 2px;
  background-color: #000000;
  position: absolute;
  bottom: -15px;
}

.sinse-box p {
  margin-bottom: 0;
  font-size: 0.7rem;
}

/*======================================
//--//-->   TESTIMONIALS
======================================*/

.testimonials-box {
  padding: 1rem 0;
}

.testimonials-box .testimonial-avatar {
  width: 80px !important;
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  border-radius: 50%;
}

.testimonial-ico {
  text-align: center;
}

.testimonial-ico span {
  font-size: 1.8rem;
  background-color: #2eca6a;
  color: #ffffff;
  padding: 0.4rem 1rem;
  border-radius: 50px;
}

.testimonial-text {
  font-style: italic;
  margin-top: 25px;
  padding: 1.5rem 1.5rem;
  background-color: #f3f3f3;
  position: relative;
}

.testimonial-text:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 25px;
  width: 0px;
  height: 0px;
  border-top: 15px solid #f3f3f3;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.testimonial-author-box {
  margin-top: 2rem;
}

.testimonial-author {
  margin-left: 1rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1.2rem;
  color: #000000;
}

/*======================================
//--//-->   FOOTER
======================================*/

.section-footer {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #333333 !important;
}
.info {
  float: left !important;
  text-align: left;
  color: #ffffff;
  padding-left: 15px;
}

.section-footer ul {
  line-height: 2;
}

.section-footer .item-list-a i {
  font-size: 18px;
  padding-right: 4px;
  color: #2eca6a;
}

.section-footer .widget-a .w-title-a {
  margin-bottom: 1rem;
}

footer {
  background: #f3f3f3;
  text-align: center;
  padding: 30px 0;
}

footer .copyright-footer {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

footer .credits {
  font-size: 14px;
}

/*======================================
//--//-->   BUTTON
======================================*/

.btn {
  transition: 0.5s ease;
}

.btn.btn-a,
.btn.btn-b {
  border-radius: 0;
  padding: 1rem 3rem;
  letter-spacing: 0.05rem;
}

.btn.btn-a {
  background-color: #000000;
  color: #ffffff;
}

.btn.btn-a:hover {
  background-color: #2eca6a;
  color: #000000;
}

.btn.btn-b {
  background-color: #2eca6a;
  color: #000000;
}

.btn.btn-b:hover {
  background-color: #000000;
  color: #ffffff;
}

.btn.btn-b-n {
  background-color: #2eca6a;
  color: #ffffff;
  border-radius: 0;
}

.btn.btn-b-n:hover {
  background-color: #26a356;
  color: #ffffff;
}

@media (min-width: 576px) {
  .intro .intro-title {
    font-size: 3.5rem;
  }

  .intro .intro-subtitle.intro-price {
    font-size: 1.3rem;
  }

  .about-img-box {
    padding-left: 4rem;
  }

  .sinse-box {
    padding: 1.5rem 2.5rem;
    bottom: 20px;
  }

  .sinse-box .sinse-title {
    font-size: 1.3rem;
  }

  .sinse-box p {
    font-size: 0.8rem;
  }

  .sinse-box p {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .link-a {
    font-size: 0.9rem;
  }

  .link-a span {
    font-size: 1.5rem;
  }

  .navbar-default.navbar-trans .nav-item,
  .navbar-default.navbar-reduce .nav-item {
    margin-left: 15px;
  }

  .navbar-default .dropdown .dropdown-menu {
    border-top: 4px solid #2eca6a;
    border-left: 0;
    display: block;
    position: absolute;
    box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(20, 19, 18, 0.1);
  }

  .box-collapse {
    width: 50%;
  }

  .intro .owl-theme .owl-dots {
    right: 200px;
  }

  .intro .intro-body {
    padding-left: 2rem;
  }

  .intro .intro-title-top {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .intro .intro-title {
    font-size: 4rem;
  }

  .intro .intro-subtitle {
    font-size: 2.5rem;
  }

  .intro .intro-subtitle.intro-price {
    padding-top: 2rem;
    font-size: 1.5rem;
  }

  .intro-single .title-single-box .title-single {
    font-size: 2.1rem;
  }

  .grid .card-box-a,
  .grid .card-box-b,
  .grid .card-box-c,
  .grid .card-box-d {
    margin-bottom: 2.5rem;
  }

  .card-box-a:hover .img-a,
  .card-box-a:hover .img-b,
  .card-box-b:hover .img-a,
  .card-box-b:hover .img-b {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .card-box-a .price-a,
  .card-box-b .price-a {
    font-size: 0.9rem;
  }

  .card-box-a:hover .card-overlay-a-content {
    bottom: 60px;
  }

  .card-box-a:hover .card-body-a {
    padding-bottom: 1rem;
  }

  .card-overlay-a-content {
    bottom: -20px;
  }

  .card-header-a .card-title-a {
    font-size: 1.3rem;
  }

  .card-info .card-info-title {
    font-size: 0.9rem;
  }

  .card-info li span {
    font-size: 0.8rem;
  }

  .card-header-b {
    bottom: 0px;
  }

  .card-header-b .title-2 {
    font-size: 1rem;
  }

  .card-header-b .date-b {
    font-size: 0.9rem;
  }

  .box-comments .list-comments .comment-author {
    font-size: 1.5rem;
  }

  .title-c {
    font-size: 1.8rem;
  }

  .card-box-d .card-overlay-hover {
    padding: 5px 15px 5px 10px;
  }

  .card-box-d .title-d {
    font-size: 1.1rem;
    margin: 0.5rem 0;
  }

  .card-box-d .content-d {
    font-size: 0.8rem;
    margin: 0.5rem 0;
  }

  .card-box-d .card-body-d p {
    margin-bottom: 8px;
    font-size: 0.8rem;
  }

  .about-img-box {
    padding-left: 5rem;
  }

  .sinse-box {
    padding: 1.5rem 3rem;
  }

  .sinse-box .sinse-title {
    font-size: 1.8rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .card-box-a:hover .card-overlay-a-content {
    bottom: 60px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .card-box-a:hover .card-overlay-a-content {
    bottom: 64px;
  }
}

@media (min-width: 992px) {
  .link-a {
    font-size: 1rem;
  }

  .link-a span {
    font-size: 18px;
  }

  .list-a li {
    width: 33.333%;
  }

  .intro .intro-title-top {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }

  .intro .intro-title {
    font-size: 3rem;
  }

  .intro .intro-subtitle.intro-price {
    padding-top: 3rem;
  }

  .intro-single .title-single-box .title-single {
    font-size: 2.5rem;
  }

  .card-box-a .price-a,
  .card-box-b .price-a {
    font-size: 1rem;
  }

  .card-overlay-a-content {
    bottom: 0px;
  }

  .card-header-a .card-title-a {
    font-size: 2rem;
  }

  .card-info .card-info-title {
    font-size: 1rem;
  }

  .card-info li span {
    font-size: 1rem;
  }

  .card-header-b {
    bottom: 20px;
  }

  .card-header-b .title-2 {
    font-size: 1.6rem;
  }

  .card-header-b .date-b {
    font-size: 1rem;
  }

  .title-c {
    font-size: 2.5rem;
  }

  .card-box-d .card-overlay-hover {
    padding: 5px 40px 5px 35px;
  }

  .card-box-d .title-d {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .card-box-d .card-body-d p {
    margin-bottom: 12px;
    font-size: 1rem;
  }

  .sinse-box {
    padding: 3rem 4.5rem;
  }

  .sinse-box .sinse-title {
    font-size: 2.2rem;
  }
}

@media (min-width: 1200px) {
  .card-box-d .card-overlay-hover {
    padding: 15px 40px 5px 35px;
  }

  .card-box-d .title-d {
    font-size: 2rem;
    margin: 1rem 0;
  }
}

@media (max-width: 991px) {
  .property-contact {
    margin-top: 2.5rem;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

@media (max-width: 767px) {
  .text-brand {
    font-size: 1.8rem;
  }

  .section-t8 {
    padding-top: 4rem;
  }

  .section-t4 {
    padding-top: 2rem;
  }

  .section-md-t3 {
    padding-top: 3rem;
  }

  .section-tb85 {
    padding: 4rem 0 2.5rem 0;
  }

  .intro-single {
    padding-top: 9rem;
  }

  .card-box-a,
  .card-box-b,
  .card-box-c,
  .card-box-d {
    margin-bottom: 2.5rem;
  }

  .card-box-a .card-overlay-a-content {
    bottom: 65px;
  }

  .card-box-a .card-body-a {
    padding-bottom: 1rem;
  }

  .property-agent {
    margin-top: 2.5rem;
  }

  .card-box-c {
    margin-bottom: 2rem;
  }

  .card-body-c {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .box-collapse .title-box-d {
    left: 35px;
  }

  .box-collapse .title-box-d .title-d {
    font-size: 1.3rem;
  }
}

#content {
  padding-top: 82px;
  padding-bottom: 5vh;
}

#content a {
  color: #000000;
  text-decoration: none;
}

#content a:hover,
#content a:active {
  color: #2eca6a;
}

#carousel {
  min-width: 100% !important;
}

.text-outline {
  color: rgb(0, 0, 0);
  text-shadow: -1px -1px 0 #2eca6a, 1px -1px 0 #2eca6a, -1px 1px 0 #2eca6a,
    1px 1px 0 #2eca6a;
}
.services-container {
  box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
  padding-top: 5vh;
  padding-bottom: 5vh;
  background-color: '#FFFFFF';
  background-repeat: 'no-repeat';
}
.section-services {
  padding-top: 5vh;
}
.single-service-card {
  padding: 20px;
}
.blockquote {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.blockquote-before {
  font-weight: bold;
  font-style: italic;
  line-height: 150%;
  font-size: medium;
  margin: 15px 0pt;
  padding: 0px 0px 5px 65px;
  width: auto;
}
.blockquote-after {
  margin-top: 0pt;
  padding: 0pt 65px 10px 0pt;
}

.page-heading {
  font-weight: bold;
  font-family: 'Comfortaa';
  color: #000000;
}

/* BACK TO TOP BUTTON */
.scroll {
  opacity: 0.3;
  background-color: #2eca6a;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  border: none;
}
.scroll:hover {
  opacity: 1;
}
.arrow-up {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  display: block;
}
/* Welcome Jumbatron*/

.welcome-heading {
  font-family: 'Courgette', cursive;
  color: #2eca6a;
  font-size: 4.5vw;
}

/* Backdrop */
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

/************************
  SIDEDRAWER
************************/
.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
.side-drawer.open {
  transform: translateX(0);
}
.side-drawer ul {
  padding-top: 50px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.side-drawer li {
  margin: 0.25rem 0;
}
.side-drawer a {
  color: #555555;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #2eca6a;
}

.side-drawer-services {
  padding-left: 50px !important;
  margin-bottom: 2px !important;
  font-size: 1rem !important;
}
/* contact details*/

.contact-details a {
  color: #555555;
  text-decoration: none;
  font-size: 1.2rem;
}

.contact-details a:hover,
.contact-details a:active {
  color: #2eca6a;
}
@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }
}

/*****************************
  TOGGLE BUTTON
*****************************/
.toggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 36px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  top: 0;
  left: 0;
}
.toggleButton:focus {
  outline: none;
}
.toggleButton-line {
  width: 30px;
  height: 2.5px;
  background: black;
}
.toggleButton-line-middle {
  width: 30px;
  height: 2.5px;
  background: #2eca6a;
}

/*****************************
  NAVBAR
*****************************/
@media (max-width: 768px) {
  .nav-items {
    display: none;
  }
  .text-brand {
    font-size: 1.25rem;
  }
  .intro-title {
    font-size: 2.5rem;
  }
}
@media (min-width: 769px) {
  .toggle-button {
    display: none;
  }
  .logo {
    margin-left: 0;
  }
}
/* 404 return to homepage*/

.not-found-return {
  padding-top: 30px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  width: 25%;
  border: 2px solid #2eca6a;
  border-radius: 50px;
  text-transform: uppercase;
}
.not-found-button {
  font-family: 'Courgette', cursive;
  color: #2eca6a;
  font-size: 1.2vw;
}
.not-found-heading {
  font-size: 6.5vw;
}
.not-found-spinner {
  -webkit-animation: rotation 2s infinite linear;
  -moz-animation: rotation 2s infinite linear;
  -o-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.not-found {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}
.not-found-404 {
  position: relative;
  height: 180px;
}
/* Cols and Rows testing */
.col1 {
  border: 1px solid red;
}
.col2 {
  border: 1px solid yellow;
}
